 

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  googleMapsAPIKey: 'AIzaSyC5u2nlByUrNzOcUsgjNgZ4oClr4qdf0Tc',
  //firebaseApi: 'AIzaSyCksD3WqPH-BhDobdZ-yF8B01jBsxomGjc',

  firebaseApi: 'AIzaSyCdDaosC6qMBegux4y4eHlEmK9RfCD5gfI',
  firebaseStorageInit: 'https://firebasestorage.googleapis.com/v0/b/gr8med-5a3d3.appspot.com/o/images%2F128x128-',
  firebaseStorageEnd: '?alt=media&token=7c97d0c2-7903-4e46-afb0-bd3b92ae0ce4',
  // baseUrl: 'https://localhost:8443',
  //baseUrl: 'https://gr8-i6tir7isnq-rj.a.run.app',
  // baseUrl: 'http://localhost:8080',
   baseUrl: 'https://gaws.gr8medicoes.com.br:8443',
  
  localUser: 'localUser',
  role: 'neblina',
  bucketAws: 'https://proeximage.s3.sa-east-1.amazonaws.com/',
  
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
