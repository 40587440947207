import { Component, OnDestroy, OnInit } from '@angular/core';

import { Platform, MenuController } from '@ionic/angular';
import {Plugins, Capacitor, AppState} from '@capacitor/core';
import { AuthService } from './auth/auth.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import {JwtHelperService} from '@auth0/angular-jwt';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  private subAuth: Subscription;
  private previousAuthState = false;
  public isAdmin: boolean;
  public isUser: boolean;
  public isSind: boolean;
  public isCond: boolean;
  constructor(
    private platform: Platform,
    private authService: AuthService,
    private menuCtrl: MenuController,

    private router: Router
  ) {
    this.initializeApp();
  }

  private  jwtHelper: JwtHelperService = new JwtHelperService();

  initializeApp() {
   // console.log(this.platform.is('hybrid'));
    this.platform.ready().then(() => {
      if (Capacitor.isPluginAvailable('SplashScreen')) {
        Plugins.SplashScreen.hide();
      }
    });
  }

  ngOnInit() {
   
    this.subAuth = this.authService.userIsAuthenticated.subscribe(isAuth => {
      if (!isAuth && this.previousAuthState !== isAuth) {
      this.router.navigateByUrl('/auth');




      this.menuCtrl.swipeEnable(false);
    } else {

   //   this.onUser();
    }
      this.previousAuthState = isAuth;
    });
   //
   // Plugins.App.addListener('appStateChange', this.checkAuthOnResume.bind(this));
  //  this.onUser();
    
  }

ngOnDestroy() {
  if (this.subAuth) {
    this.subAuth.unsubscribe();
    // Plugins.App.removeListener('appStateChange', this.checkAuthOnResume);
  }

}

  onLogout() {
  this.authService.onLogout();

  }
  ionViewWillEnter() {

  //  this.onUser();
  }
/*
  private checkAuthOnResume(state: AppState) {
    if (state.isActive) {
      this.authService
        .autoLogin()
        .pipe(take(1))
        .subscribe(success => {
          if (!success) {
            this.onLogout();
          }
        });
    }
}
*/

}
