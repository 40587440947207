export class UserDto {
    constructor(
        public email: string,
        public senha: string,
        public cpfOuCnpj?: string,
        public medidor?: string,
       
     ) {};


    }