import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable } from 'rxjs'; // IMPORTANTE: IMPORT ATUALIZADO
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment';
import { catchError } from 'rxjs/operators';
import { AlertController } from '@ionic/angular';
import { Router } from '@angular/router';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(public authService: AuthService, private alertCtrl: AlertController, private router: Router) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const localUser = this.authService.getLocalUser();

        const N = environment.baseUrl.length;
        const requestToAPI = request.url.substring(0, N) === environment.baseUrl;

            // add authorization header with jwt token if available

        if (localUser && requestToAPI) {
                request = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${localUser._token}`
                    }
                });
            }
        return next.handle(request);
        }

    }
