import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable, throwError } from 'rxjs'; // IMPORTANTE: IMPORT ATUALIZADO
import { AuthService } from './auth.service';

import { catchError } from 'rxjs/operators';
import { AlertController } from '@ionic/angular';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptorError implements HttpInterceptor {

  constructor(public authService: AuthService, private alertCtrl: AlertController, private router: Router) {
    }


 intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError(error => {
                console.log(error);
                this.showAlert(error);
                if (error.status === 403) {
                this.router.navigateByUrl('/auth');
                }
                if (error.status === 404) {
                  //this.router.navigateByUrl('/auth');
                  }
                return throwError( error);

            })
        );
 }

  private showAlert(erroObj) {
    const erro = erroObj.error.error ? erroObj.error : JSON.parse(erroObj.error);
    const messagem = erro.message ? erro.message : erro;
    if ( erroObj.status === 403) {
      this.alertCtrl
      .create({
        header: 'Autenticação Expirada',
        message: 'Faça login novamente',
        buttons: ['Ok']
      })
      .then(alertEl => alertEl.present());
  } else {


    
    this.alertCtrl
              .create({
                header: 'Erro: ' + erroObj.status,
                message: messagem,
                buttons: ['Ok']
              })
              .then(alertEl => alertEl.present());
          }

          if ( erroObj.status === 404) {
            this.alertCtrl
            .create({
              header: 'Erra remessa Servidor',
              message: 'End Point',
              buttons: ['Ok']
            })
            .then(alertEl => alertEl.present());
        } else {
      
      
          
          this.alertCtrl
                    .create({
                      header: 'Erro: ' + erroObj.status,
                      message: messagem,
                      buttons: ['Ok']
                    })
                    .then(alertEl => alertEl.present());
                }

        }

}
