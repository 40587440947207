import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';


const routes: Routes = [
 
  { path: '', redirectTo: 'places', pathMatch: 'full' },

  { path: 'auth', 
    loadChildren: './auth/auth.module#AuthPageModule' },

  { path: 'places',
   loadChildren: './places/places.module#PlacesPageModule',
   canLoad: [AuthGuard],
   data: {
    allowedRoles: ['ROLE_ADMIN', 'ROLE_USER']
  },
},
  { path: 'profile',
   loadChildren: './auth/profile/profile.module#ProfilePageModule',
   canLoad: [AuthGuard]},

  { path: 'moradores',
    canActivate: [AuthGuard],
     data: {
    allowedRoles: ['ROLE_ADMIN']
  },
    loadChildren: './moradores/moradores/moradores.module#MoradoresPageModule',
    },

  { path: 'moradores/:condominioId', canActivate: [AuthGuard],
  data: {
   allowedRoles: ['ROLE_ADMIN']
 },
   loadChildren: './moradores/moradores/moradores.module#MoradoresPageModule',
    },
  
   { path: 'edit-moradores',
   canActivate: [AuthGuard],
   data: {
    allowedRoles: ['ROLE_ADMIN']
  },
    loadChildren: './moradores/edit/edit-moradores/edit-moradores.module#EditMoradoresPageModule',
    },

   { path: 'edit-moradores/:moradorId',
    canActivate: [AuthGuard],
    data: {
    allowedRoles: ['ROLE_ADMIN']
  },
     loadChildren: './moradores/edit/edit-moradores/edit-moradores.module#EditMoradoresPageModule',
   },

   { path: 'new-moradores', canActivate: [AuthGuard],
    data: {
    allowedRoles: ['ROLE_ADMIN']
   },
     loadChildren: './moradores/new/new-moradores/new-moradores.module#NewMoradoresPageModule', 
     canLoad: [AuthGuard]},

   { path: 'new-moradores/:condominioId', canActivate: [AuthGuard],
   data: {
    allowedRoles: ['ROLE_ADMIN']
  },
   loadChildren: './moradores/new/new-moradores/new-moradores.module#NewMoradoresPageModule',
  },

   { path: 'contas/:condominioId', canActivate: [AuthGuard],
   data: {
    allowedRoles: ['ROLE_ADMIN']
  },
    loadChildren: './contas/contas/contas.module#ContasPageModule',
    canLoad: [AuthGuard]},
  
   { path: 'edit-contas/:contaId', canActivate: [AuthGuard],
   data: {
    allowedRoles: ['ROLE_ADMIN']
  },
   loadChildren: './contas/edit-contas/edit-contas.module#EditContasPageModule',
   canLoad: [AuthGuard]},

  { path: 'new-contas/:condominioId/:new',
   canActivate: [AuthGuard],
   data: {
    allowedRoles: ['ROLE_ADMIN', 'ROLE_USER']
  },
   loadChildren: './contas/new-contas/new-contas.module#NewContasPageModule'},


  { path: 'rateio/:moradorId/:condominioId', canActivate: [AuthGuard],
  data: {
   allowedRoles: ['ROLE_ADMIN']
 },
    loadChildren: './rateio/rateio/rateio.module#RateioPageModule' },


  { path: 'new-rateio/:moradorId/:condominioId/:leitura/:new', canActivate: [AuthGuard],
  data: {
   allowedRoles: ['ROLE_ADMIN', 'ROLE_USER']
 },
    loadChildren: './rateio/new-rateio/new-rateio.module#NewRateioPageModule' },

  { path: 'edit-rateio/:contaId/:ultimaContaId/:leitura', canActivate: [AuthGuard],
  data: {
   allowedRoles: ['ROLE_ADMIN', 'ROLE_USER']
 },
    loadChildren: './rateio/edit-rateio/edit-rateio.module#EditRateioPageModule' },

    
  { path: 'auditoria/:condominioId/:dataRateio/:usaRateioAtual', canActivate: [AuthGuard],
  data: {
   allowedRoles: ['ROLE_ADMIN',]
 },
    loadChildren: './rateio/auditoria/auditoria/auditoria.module#AuditoriaPageModule' },

   { path: 'usuario', canActivate: [AuthGuard],
   data: {
    allowedRoles: ['ROLE_ADMIN']
  },
     loadChildren: './user/usuario/usuario.module#UsuarioPageModule'},


  { path: 'edit-usuario/:usuarioId', canActivate: [AuthGuard],
  data: {
   allowedRoles: ['ROLE_ADMIN']
 },
   loadChildren: './user/edit-usuario/edit-usuario.module#EditUsuarioPageModule' },

  { path: 'add-usuario', canActivate: [AuthGuard],
  data: {
    allowedRoles: ['ROLE_ADMIN']
  },
   loadChildren: './user/add-usuario/add-usuario.module#AddUsuarioPageModule' },
  
  { path: 'moradores-leitura/:condominioId', canActivate: [AuthGuard],
   data: {
    allowedRoles: ['ROLE_ADMIN', 'ROLE_USER']
  },
    loadChildren: './places/leitura/moradores-leitura/moradores-leitura.module#MoradoresLeituraPageModule' },
   
    { path: 'moradores-leitura/:condominioId/:medidor', canActivate: [AuthGuard],
    data: {
     allowedRoles: ['ROLE_ADMIN', 'ROLE_USER']
   },
     loadChildren: './places/leitura/moradores-leitura/moradores-leitura.module#MoradoresLeituraPageModule' },

  { path: 'config', canActivate: [AuthGuard],
    data: {
    allowedRoles: ['ROLE_ADMIN']},
    loadChildren: './config/config/config.module#ConfigPageModule' , },
  { path: 'downloads', loadChildren: './downloads/downloads.module#DownloadsPageModule' },
  
  { path: 'up-contas/:condominioId/:qtdeMedidor/:qtdeSaldoPendente',  canActivate: [AuthGuard],
    data: {
    allowedRoles: ['ROLE_ADMIN', 'ROLE_USER']},
    loadChildren: './config/up-contas/up-contas.module#UpContasPageModule' },
   
    { path: 'carrosel/:condominioId/:isLeitura/:dataRateio/:usaRateioAtual', canActivate: [AuthGuard],
    data: {
    allowedRoles: ['ROLE_ADMIN', 'ROLE_USER']},
    loadChildren: './rateio/auditoria/carrosel/carrosel.module#CarroselPageModule' },


  { path: 'analise',  canActivate: [AuthGuard],
    data: {
    allowedRoles: ['ROLE_ADMIN', 'ROLE_USER']},
    loadChildren: './dash/analise/analise.module#AnalisePageModule'},
    
  { path: 'calendar', loadChildren: './calendar/calendar.module#CalendarPageModule' }

];


@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
