import { Injectable } from '@angular/core';
import {  CanLoad, Route, UrlSegment, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from './auth.service';
import {JwtHelperService} from '@auth0/angular-jwt';

import { AlertController } from '@ionic/angular';
import { CanActivate } from '@angular/router/src/utils/preactivation';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanLoad, CanActivate  {
  path: ActivatedRouteSnapshot[];
  route: ActivatedRouteSnapshot;

constructor(private authService: AuthService, private router: Router, private alertCtrl: AlertController) {}
private  jwtHelper: JwtHelperService = new JwtHelperService();

 canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
        const chave  = this.authService.getLocalUser();

        if ( chave ) {
        const chaveToken = this.authService.getLocalUser()._token;
        const  dateToken = this.jwtHelper.getTokenExpirationDate(chaveToken);


        if (dateToken && dateToken < new Date()) {
          this.authService.setLocalUser(null);
          this.showAlertTokenExpirou();
          return false;
        } else {
          return true;
        }
        }
        this.router.navigateByUrl('/auth');
        return false;
      }


      private showAlertTokenExpirou() {
        this.alertCtrl
          .create({
            header: 'Sua autenticação expirou.',
            message: 'Refaça a autenticação',
            buttons: ['Ok']
          })
          .then(alertEl => {
            alertEl.present();
            this.router.navigateByUrl('/auth');
      });

    }


    canActivate(
      next: ActivatedRouteSnapshot,
      state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
      const allowedRoles = next.data.allowedRoles;
      const isAuthorized = this.isAuthorized(allowedRoles);
 
      if (!isAuthorized) {
        this.router.navigateByUrl('/auth');
      }

      return isAuthorized;
    }






    private showAcessoNegado() {
      this.alertCtrl
        .create({
          header: 'Acesso  Negado.',
          message: 'Refaça a autenticação.',
          buttons: ['Ok']
        })
        .then(alertEl => {
          alertEl.present();
          this.router.navigateByUrl('/auth');
    });

  }

  isAuthorized(allowedRoles: string[]): boolean {

    let roles: any =  null;
    

    if (allowedRoles == null || allowedRoles.length === 0) {
      return true;
    }
    const chave  = this.authService.getLocalUser();


    if ( chave ) {
    const chaveToken = this.authService.getLocalUser()._token;
    const  dateToken = this.jwtHelper.getTokenExpirationDate(chaveToken);
    const  payload = this.jwtHelper.decodeToken(chaveToken);
    roles = payload.roles;
    const isRole = allowedRoles.includes(roles[0].authority);
 
    if (dateToken && dateToken < new Date())  {
      this.authService.setLocalUser(null);
      this.showAlertTokenExpirou();
      return false;
    }  else if ( !isRole)  {
      this.authService.setLocalUser(null);
      this.showAcessoNegado();
      return false;
    }  else  {
      return true;
    }
    }

    return false;
  }

 }


